.timeline {
    display: flex;
    position: relative;
  }
  
  .timeline-hour {
    flex: 1;
    text-align: center;
    position: relative;
  }
  
  .timeline-vertical-line {
    border-left: 1px solid #000;
    height: 100%;
    position: absolute;
    top: 0;
  }
  
  .timeline-event {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .checkin-tile,
  .break-tile,
  .checkout-tile {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .checkin-tile {
    background-color: rgb(70, 184, 70);
    color: white;
  }
  
  .break-tile {
    background-color: rgb(65, 65, 150);
    color: white;
  }
  
  .checkout-tile {
    background-color: rgb(224, 60, 60);
    color: white;
  }
  .break-oval {
    background-color: rgb(75, 167, 75); /* Dark green color for Break oval */
  }
  .checkout-oval {
    background-color: rgb(228, 58, 58); /* Red color for Check-Out oval */
  }
  
  
  